<template>
  <!-- eslint-disable -->
  <v-dialog
    :value="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="rounded-0" flat>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          <div class="caption font-weight-bold">
            Stock & Inventory
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="$emit('close', true)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div style="max-height: calc(100vh - 64px);overflow-y: auto;">
        <div class="pa-4">
          <v-row no-gutters>
            <v-col cols="6" class="col-sm-4 pr-1 pr-sm-0">
              <v-select
                v-model.number="stokistFilter"
                :disabled="parseInt($store.state.user.isstockist) ? true : false"
                label="Select Source"
                :items="[{ id: 0, user_name: 'All' }].concat($store.state.stokist)"
                item-value="id"
                item-text="user_name"
                block
                outlined
                dense
                hide-details
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="6" class="col-sm-4 pl-1 px-sm-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :return-value.sync="dates"
                left
                offset-y
                :close-on-content-click="false"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateRangeText"
                    label="Date Ranges"
                    append-icon="mdi-calendar-outline"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                    class="accent"
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="dates = []"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  no-title
                  light
                  class="force-light-font"
                  range
                >
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    class="text-capitalize"
                    small
                    @click="$refs.menu.save(dates)"
                  >
                    Ok
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" class="col-sm-4 pt-2 pt-sm-0">
              <v-text-field
                v-model="search"
                label="Search Product"
                outlined
                append-icon="mdi-magnify"
                block
                dense
                :color="$vuetify.theme.dark ? 'grey' : 'primary'"
                class="accent mb-4 mb-sm-0"
                autocomplete="off"
                hide-details
              />
            </v-col>
            <v-col cols="12" class="pt-4 slider-moving">
              <v-slider
                v-model="SlowFastMoving.value"
                :min="SlowFastMoving.min"
                :max="SlowFastMoving.max"
                :thumb-size="45"
                color="indigo darken-2"
                track-color="red darken-2"
                dense
                append-icon="mdi-magnify-plus-outline"
                prepend-icon="mdi-magnify-minus-outline"
              >
                <template v-slot:thumb-label="{ value }">
                  {{ $kFormatter(value) }}
                </template>
              </v-slider>
              <div class="d-flex align-center justify-center f-width text-center caption grey--text text--darken-2">
                <small class="subtitle-2 indigo--text text--darken-2 font-weight-bold">Slow</small>
                <v-icon x-small class="mx-2">
                  mdi-arrow-left
                </v-icon>
                <div class="d-flex align-center justify-center" style="line-height: 100%;">
                  <v-icon small class="mr-1">
                    mdi-transfer
                  </v-icon>
                  Moving
                </div>
                <v-icon x-small class="mx-2">
                  mdi-arrow-right
                </v-icon>
                <small class="subtitle-2 red--text text--darken-2 font-weight-bold">Fast</small>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          class="accent rounded-0"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.name="{ item }">
            <div :class="[parseInt(item.status) ? '' : 'product-not-active']" class="caption-small font-weight-bold d-flex align-center" style="min-width: 95px;">
              {{ item.name }}
              <v-icon v-if="parseInt(item.is_package)" x-small color="blue" class="ml-1">
                mdi-package-variant-closed
              </v-icon>
              <v-icon v-if="parseInt(item.is_merchandise)" x-small color="purple" class="ml-1">
                mdi-wallet-giftcard
              </v-icon>
              <v-icon v-if="parseInt(item.is_additional)" x-small color="orange" class="ml-1">
                mdi-basket-plus-outline
              </v-icon>
              <v-chip v-if="!parseInt(item.status)" x-small class="px-1 ml-2 font-weight-regular">
                <v-icon x-small color="orange" class="mr-1">
                  mdi-information
                </v-icon>
                Not Active
              </v-chip>
            </div>
          </template>
          <template v-slot:item.hpp="{ item }">
            <div class="caption-small font-weight-bold" style="min-width: 95px;">
              <!-- {{ $price(item.hpp) || $price(item.price_hpp) }} -->
              {{ $price(item.price_hpp) }}
              
            </div>
          </template>
          <template v-slot:item.source_total="{ item }">
            <div class="d-flex caption-small align-center justify-space-between font-weight-bold py-1" style="min-width: 95px;">
              <!-- {{ GET_STOCK_LIMIT_PERCENTAGE(parseInt(item.my_stock || 0), item.stock_limit) }} -->
              <!-- <v-btn :color="MOVING_COLOR(parseInt(item.source_total.split('/')[1]) || 0)" class="mr-2" fab x-small depressed dark> -->
              <v-btn :color="MOVING_COLOR(parseInt(item.my_stock || 0), item.stock_limit)" class="mr-2" fab x-small depressed dark>                
                <v-icon small class="font-weight-bold">
                  mdi-transfer
                </v-icon>
              </v-btn>
              <span>                
                <!-- {{ (item.source_total !== "0" ? ($price(item.source_total.split('/')[0])) : $price(item.price))   }} -->
                {{ $price(item.price) }}
                
              </span>
            </div>
          </template>
          <template v-slot:item.source_unit="{ item }">
            <div class="caption-small font-weight-bold">
              <span v-if="!parseInt(item.is_package)" class="blue--text text-darken-2">{{ parseInt(item.source_unit || 0) }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:item.source_package="{ item }">
            <div class="caption-small font-weight-bold">
              <span v-if="!parseInt(item.is_package)" class="blue--text text-darken-2">{{ parseInt(item.source_package || 0) }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:item.source_merchandise="{ item }">
            <div class="caption-small font-weight-bold">
              <span v-if="!parseInt(item.is_package)" class="purple--text text-darken-2">{{ parseInt(item.source_merchandise || 0) }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:item.source_additional="{ item }">
            <div class="caption-small font-weight-bold">
              <span v-if="!parseInt(item.is_package)" class="orange--text text-darken-2">{{ parseInt(item.source_additional || 0) }}</span>
              <span v-else>-</span>
            </div>
          </template>
          <template v-slot:item.source_total_products="{ item }">
            <div class="caption-small font-weight-bold">
              {{ ((parseInt((item.source_total ? item.source_total.split('/')[1] : 0)) - parseInt(item.source_package || 0) - parseInt(item.source_merchandise || 0) - parseInt(item.source_additional || 0)) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_vendor="{ item }">
            <div class="caption-small font-weight-bold">
              {{ parseInt(item.is_package) ? '-' : (parseInt(item.my_stock_vendor) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_in="{ item }">
            <div class="caption-small font-weight-bold">
              {{ parseInt(item.is_package) ? '-' : (parseInt(item.my_stock_in) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_out_sellv2="{ item }">
            <div class="caption-small font-weight-bold">
              {{ (parseInt(item.my_stock_out_sellv2) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_out_lost="{ item }">
            <div class="caption-small font-weight-bold">
              {{ parseInt(item.is_package) ? '-' : (parseInt(item.my_stock_out_lost) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_out_moving="{ item }">
            <div class="caption-small font-weight-bold">
              {{ parseInt(item.is_package) ? '-' : (parseInt(item.my_stock_out_moving) || 0) }}
            </div>
          </template>
          <template v-slot:item.my_stock_v2="{ item }">
            <div :class="[parseInt(item.is_package) ? '' : $StockLimitColor(item.stock_limit, parseInt(item.my_stock))]" class="d-flex fill-width fill-height caption-small font-weight-bold align-center justify-center pa-4">
              {{ parseInt(item.is_package) ? '-' : (parseInt(item.my_stock_v2) || 0) }}
            </div>
          </template>
          <template v-slot:item.stocks_out="{ item }">
            <div class="caption-small" style="min-width: 95px;">
              <div v-if="item.stocks_out" class="font-weight-bold ">
               <!--  <v-btn color="orange darken-2 ml-2" dark depressed x-small class="rounded-xl text-capitalize d-flex align-center" @click.prevent="item.stock_list_stokist.length ? item.stock_list_stokist = [] : LoadProductStokist(item.id)">                  
                  <v-icon x-small class="caption-small mr-1">                    
                    mdi-eye-off-outline
                  </v-icon>
                  <small style="line-height: 100%;">
                    Source/s
                  </small>
                </v-btn> -->
                <v-expansion-panels class="custom-expansion-panel">
                  <v-expansion-panel v-for="ic in itemsContainer" :key="item.id">
                    <v-expansion-panel-header class="custom-expansion-panel-header">
                      <span class="custom-expansion-panel-text">
                        {{ ic.title }}
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="custom-expansion-panel-content">
                      <v-btn v-for="(s, iS) in item.stocks_out.split(',')" :key="'sell-global-source-' + iS" x-small depressed dark color="text-capitalize rounded-pill orange darken-2" class="mr-1 my-1" style="font-size: 9px;" @click.prevent="$emit('show-history', { id: item.id, name: item.name, source: s, user: stokistFilter })">
                        {{ s }}
                      </v-btn>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
               
              </div>
              <div v-else class="red--text">
                no-data
              </div>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn x-small outlined class="rounded-pill text-capitalize ml-2 purple--text d-flex align-center px-2" @click.prevent="$emit('show-history', { id: item.id, name: item.name, user: stokistFilter })">
              <v-icon x-small class="mr-1">
                mdi-clipboard-outline
              </v-icon>
              History
            </v-btn>
          </template>
          <template v-slot:footer>
            <div :class="[$vuetify.theme.dark ? '' : 'grey lighten-4']" class="pt-2 pb-3 px-4 px-md-8">
              <v-divider class="mt-2 mb-3" />
              <div class="d-flex justify-space-between flex-column flex-sm-row">
                <div class="d-flex align-center justify-center justify-sm-start font-weight-bold">
                  Summary Reports :
                </div>
                <div class="d-flex justify-center align-center align-sm-end flex-column body-2">
                  <span class="font-weight-bold caption-small">
                   Quantity Summary : {{ tableQuantity.total }} items
                  </span>
                  <v-divider class="my-1" style="width: 75px;" />
                  <span v-if="$roles($store.state.user.uid_roles, 'product-stockcheck-hpp')" class="font-weight-bold caption-small">
                    Hpp {{ tableQuantity.total_summary | price }}
                  </span>
                  <!-- <span class="font-weight-bold caption-small">
                    Subtotal (Before Discount) {{ tableQuantity.total_summary_normal | price }}
                  </span>
                  <span class="font-weight-bold caption-small">
                    Discount {{ (parseInt(tableQuantity.total_summary_normal) - parseInt(tableQuantity.total_summary)) | price }}
                  </span>
                  <v-divider class="my-1" style="width: 75px;" />
                  <span class="font-weight-bold caption-small">
                    Omzet {{ tableQuantity.total_summary | price }}
                  </span>
                  <span v-if="$roles($store.state.user.uid_roles, 'product-stockcheck-hpp')" class="font-weight-bold caption-small">
                    Hpp {{ tableQuantity.total_summary_hpp | price }}
                  </span>
                  <v-divider v-if="$roles($store.state.user.uid_roles, 'product-stockcheck-margin')" class="my-1" style="width: 75px;" />
                  <span v-if="$roles($store.state.user.uid_roles, 'product-stockcheck-margin')" class="font-weight-bold caption-small">
                    Margin {{ (parseInt(tableQuantity.total_summary) - parseInt(tableQuantity.total_summary_hpp)) | price }}
                  </span> -->
                </div>
              </div>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
let searchTimeout = null
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data: (vm) => ({
    SlowFastMoving: {
      min: 0,
      max: 100,
      value: 50
    },
    dates: [],
    menu: false,
    search: '',
    table: [],
    tableLoading: true,
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    options: {},
    tableQuantity: {
      total_summary_normal: 0,
      total_summary: 0,
      total: 0,
      total_summary_hpp: 0
    },
    stokistFilter: vm.$store.state.user.id,
    headers: [
      // { text: '#ID', value: 'id' },
      {
        text: 'Name',
        align: 'start',
        value: 'name'
      },
      {
        text: 'Hpp',
        align: 'end',
        value: 'hpp'
      },
      {
        text: 'Product Sell (Before Discount)',
        align: 'end',
        value: 'source_total'
      },
      {
        text: 'Order Source (Selling)',
        align: 'start',
        value: 'stocks_out',
        sortable: false
      },
      {
        text: 'Per Unit (Selling)',
        align: 'end',
        value: 'source_unit'
      },
      {
        text: 'In Package (Selling)',
        align: 'end',
        value: 'source_package'
      },
      {
        text: 'Merchandise',
        align: 'end',
        value: 'source_merchandise'
      },
      {
        text: 'Additional',
        align: 'end',
        value: 'source_additional'
      },
      {
        text: 'Average',
        align: 'end',
        value: 'source_total_products'
      },
      {
        text: 'Stock In (Vendor)',
        align: 'end',
        value: 'my_stock_vendor'
      },
      {
        text: 'Stock In (Moving)',
        align: 'end',
        value: 'my_stock_in'
      },
      {
        text: 'Stock Out (Selling)',
        align: 'end',
        value: 'my_stock_out_sellv2'
      },
      {
        text: 'Stock Out (Missing)',
        align: 'end',
        value: 'my_stock_out_lost'
      },
      {
        text: 'Stock Out (Moving)',
        align: 'end',
        value: 'my_stock_out_moving'
      },
      {
        text: 'Actual Stock',
        align: 'end',
        value: 'my_stock_v2'
      },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    syncStocks: [],
    itemsContainer: [
      { id: 1, title: 'Source/s', content: 'Content for Panel 1' }
    ]
  }),
  watch: {
    show (v) {
      this.table = []
      if (v) {
        setTimeout(() => {
          this.STOCKS_GET()
        }, 300)
      }
    },
    dates () {
      this.STOCKS_GET(this.search)
    },
    search (q) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.STOCKS_GET(q)
      }, 300)
    },
    options: {
      handler () {
        this.STOCKS_GET(this.search)
      },
      deep: true
    },
    stokistFilter () {
      this.STOCKS_GET(this.search)
    }
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  mounted () {
  },
  methods: {
    GET_STOCK_LIMIT_PERCENTAGE (value, stockLimit) {
      if (Number(this.SlowFastMoving.value) < 1) {
        return ''
      } else if (stockLimit === 0) {
        return 'stockLimit = 0'
      } else if (value > stockLimit) {
        return value > stockLimit + ' kali lipat'
      } else if ((value / stockLimit) < (this.SlowFastMoving.value / 100)) {
        return value + ' ' + stockLimit + ' ' + value / stockLimit + ' ' + this.SlowFastMoving.value / 100
      }
    },
    MOVING_COLOR (value, stockLimit) {
      if (Number(this.SlowFastMoving.value) < 1) {
        return ''
      } else if (stockLimit === 0) {
        return 'indigo darken-2'
      } else if (value > stockLimit) {
        return value > stockLimit + ' kali lipat'
      } else if ((value / stockLimit) < (this.SlowFastMoving.value / 100)) {
        return 'red darken-2'
      } else {
        return 'indigo darken-2'
      }
    },
    STOCKS_GET (q) {
      this.tableQuantity.total = 0
      this.tableQuantity.total_summary = 0
      this.tableQuantity.total_summary_normal = 0
      this.tableQuantity.total_summary_hpp = 0
      this.tableLoading = true
      const fromTo = this.GetFromTo(this.dates)
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const queries = '?x=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy ? (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '') : '')
      this.$store.dispatch('STOCKS_GET', queries + (q ? '&q=' + (q || '') : '') + (fromTo || '') + (parseInt(this.stokistFilter) ? ('&user=' + parseInt(this.stokistFilter)) : ''))
        .then((res) => {
          // console.log('[STOCKS_GET] dispatch:', res.data.data)
          if (res.status) {
            this.table = res.data.data.data || []
            this.tableTotal = parseInt(res.data.data.total) || 0
            this.tableQuantity.total = parseInt(res.data.data.mutation.summary_quantity) || 0
            this.tableQuantity.total_summary = parseInt(res.data.data.mutation.summary_quantity_nominal) || 0
            this.tableQuantity.total_summary_normal = parseInt(res.data.data.mutation.summary_quantity_nominal_normal) || 0
            this.tableQuantity.total_summary_hpp = parseInt(res.data.data.mutation.summary_hpp) || 0
            this.MaxValue(res.data.data.data)
          }
          this.tableLoading = false
        })
    },
    MaxValue (data) {
      this.SlowFastMoving.max = 100
      for (let d = 0; d < data.length; d++) {
        const getValue = data[d].source_total ? parseInt(data[d].source_total.split('/')[1]) : 0
        if (getValue > this.SlowFastMoving.max) {
          this.SlowFastMoving.max = getValue
        }
      }
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return ('&from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            return (this.search, '&from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        }
      }
      return ''
    },
    CheckoutSyncInventory () {
      if (!confirm('Process Moving Stock ?')) {
        return false
      }
      const prepareTrx = {
        id: null,
        trx_source: '',
        user: parseInt(this.$store.state.user.id),
        buyer: parseInt(this.$store.state.user.id),
        uid: parseInt(this.$store.state.user.uid) || 0,
        shipping_address: '',
        shipping_courier: '',
        shipping_cost_id: 0,
        shipping_weight: 0,
        shipping_total: 0,
        platform: '',
        notes: '',
        status: 4,
        sync: 1,
        created: null
      }
      this.$store.dispatch('TRX_SYNC', {
        trx: prepareTrx,
        items: this.syncStocks
      }).then((res) => {
        if (res.status) {
          this.$emit('reload', true)
          this.$emit('reload-product', true)
          this.step = 1
          this.$store.commit('SNACKBAR', { show: true, text: 'Stock Moving Created' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.slider-moving {
  .v-messages {
    display: none!important;
  }
}
.product-not-active {
  opacity: .5;
}
.custom-expansion-panel-header .v-expansion-panel-header__icon {
  /* display: none; */
}
.custom-expansion-panel-text {
  font-size: 11px;
}
.custom-expansion-panel {
  border:none;
}
.custom-expansion-panel-content {
  padding:0px;
}
</style>
